













import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyList from '@/components/surveys/SurveyList.vue';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import NewButton from '@/components/ui/NewButton.vue';
import {Route} from '@/enums';
import {surveyModule} from '@/store/modules';

@Component({
  components: {PageTitle, SurveyList, NewButton, Page},
})
export default class Surveys extends Vue {
  public goToSurveyWizard() {
    this.$router.push({name: Route.SurveyWizard});
  }

  created(): void {
    surveyModule.reset();
  }
}
